const options = [
    {
        value: '',
        text: 'Select Type'
    },
    {
        value: 'Student',
        text: 'Student'
    },
    {
        value: 'Staff',
        text: 'Staff'
    },
];

export default options;