const options = [
    { value: '', text: 'Select Relationship' },
    { value: 'Father', text: 'Father' },
    { value: 'Mother', text: 'Mother' },
    { value: 'Brother', text: 'Brother' },
    { value: 'Sister', text: 'Sister' },
    { value: 'Uncle', text: 'Uncle' },
    { value: 'Aunt', text: 'Aunt' },
    { value: 'Grandfather', text: 'Grandfather' },
    { value: 'Grandmother', text: 'Grandmother' },
    { value: 'Other', text: 'Other' },
];

export default options;