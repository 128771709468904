import moment from 'moment';

export function resetFields(fields) {
    Object.values(fields).forEach(field => {
        field?.reset?.();
    });
}

export function disableFields(fields) {
    Object.values(fields).forEach(field => {
        field?.disable?.();
    });
}

export function formToModel(fields) {
    let model = {};

    for (let key of Object.keys(fields)) {
        const field = fields[key];
        const value = field?.state?.value;

        if (value instanceof moment) {
            model[key] = value.format("MM/DD/YYYY");
        } else {
            model[key] = value;
        }
    }

    return model;
}

export function runValidations({ form = {}, requiredFields = [] }) {
    let results = [];
    const fields = Object.keys(form);

    for (let key of fields) {
        const field = form[key];

        if (!requiredFields.includes(key)) {
            field?.setAsValid?.();
            continue;
        }

        const result = field?.validate();

        if (result?.message) {
            results.push({ key, text: result?.message });
        } 
    }

    return results;
}