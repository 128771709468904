import { useState, useEffect } from 'react';

// Set debouncedValue to value (passed in) after the specified delay
function useDebounce(value, delay) {

    const [debouncedValue, setDebouncedValue] = useState(value);

    const dependency = (typeof value === "object")
        ? JSON.stringify(value)
        : value;

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [dependency, delay]);

    return debouncedValue;
}

export default useDebounce;