const options = [
    {
        value: '',
        text: 'Select Option'
    },
    {
        value: 'mobile',
        text: 'Mobile'
    },
    {
        value: 'house',
        text: 'House'
    },
    {
        value: 'work',
        text: 'Work'
    },
    {
        value: 'other',
        text: 'Other'
    },
];

export default options;