const options = [
  { value: "", text: "Select Test Result" },
  {
    value: "Detected",
    text: "Positive",
  },
  {
    value: "NotDetected",
    text: "Negative",
  },
];

export default options;
