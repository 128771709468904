export default [
    "Medicare",
    "Health Plus Amerigroup",
    "Wellcare NY",
    "AETNA",
    "Fidelis Care",
    "Metroplus",
    "Magnacare",
    "EMPIRE BLUE CROSS / BLUE SHIELD",
    "United Health Care",
    "AFFINITY HEALTH PLAN",
    "HealthFirst",
    "1199 SEIU NATION BENEFIT FUND",
    "Medicaid",
    "Neighborhood Health",
    "Amerihealth",
    "Easy Choice Ins.Com.",
    "ELDERPLAN",
    "HealthCare Partners IPA",
    "HEALTH NET",
    "Humana",
    "PHCS",
    "Vytra",
    "Touchstone Health",
    "Bricklayers Welfare fund",
    "Great West",
    "APWU",
    "AARP/SUPPLEMENTARY FOR MEDICARE",
    "US Family Health Plan",
    "NALC HEALTH BENEFIT PLAN",
    "Guildnet, Inc",
    "LOCAL 210 UNITY WELFARE FUND",
    "American Plan Administrators",
    "GEHA",
    "Universal Health Care",
    "Alicare",
    "Tricare",
    "VNS Choice",
    "American Group Administrators, INC",
    "UMR",
    "United Health Plus",
    "MVP Health Ins.",
    "Medicare Railroad",
    "Crossroads Healthcare Management",
    "Amalgamated National Health Fund",
    "Hudson Health Plan",
    "Chesterfield Resources",
    "Madelaine/Local 1222",
    "Gilsbar, Inc.",
    "EOC Nassau County, Inc",
    "Champva",
    "Specialists In Employee Benefit Administration, LTD",
    "Religious Comprehensive Trust/ Christian Brothers EBT",
    "Island Group Administrator",
    "Nippon Life Insurance",
    "Group Health Inc.",
    "Emblem Health (HIP)",
    "Principal Life Insurance Co",
    "NY Presbyterian System Select Health",
    "Core Source",
    "Johns Hopkins Employer Health Programs",
    "C and R Consulting Incorporation",
    "AMERICHOICE BY UNITED",
    "International Benefits Administrators",
    "Starbridge Limited Benefit Plan",
    "STARMARK BY AETNA",
    "MACY'S HR SERVICES",
    "sheet metall workers union",
    "STRATEGIC RESOURCES COMPANY",
    "BANKERS CONSECO LIFE INSURANCE CO",
    "Harrington Health insurance",
    "Maloney&Associates",
    "Pan-American Life Insurance Co.",
    "Motion Picture Industry HealthPlan",
    "Mail Handlers Benefit Plan",
    "RELIANCE STANDARD SPECIALTY PRODUCT ADMINISTRATION",
    "Trans American life",
    "UNITED HEALTH INTEGRATED SERVICES",
    "Genworth Life and Annuity Insurance Company",
    "Nationwide",
    "Sterling Life Insurance Company",
    "Qual Care Incorporation",
    "INTEGRITY HEALTH",
    "First United American Life Ins. Co.",
    "Mutual of Omaha",
    "Guild-Times Benefits Fund",
    "IBA International Benefits Administrators",
    "Gerber Life Ins.",
    "CENTERLIGHT",
    "NY CC Hotel Association",
    "Pointers, Cleaners & Caulkers Welfare Fund, PCC 1st FL",
    "Health Plus Elite",
    "Cement Concrete Workers",
    "Harvard Pilgrim Healthcare",
    "Selman and Company",
    "Hartford Medical Ins. Plans",
    "Allied Benefit Systems",
    "Allied Security Health & Welfare Fund",
    "Hip Montefiore",
    "ASRM Att:Claims department",
    "Pavers and road builders",
    "Cigna Healthcare Ins",
    "State Ins. Fund Ins.",
    "Life Benefit Plan",
    "Klais and Company",
    "United American Ins. Co.",
    "Univera Healthcare Ins",
    "Care to Care Ins.",
    "Public Service Mutual Ins.",
    "WEA Trust Ins.",
    "Oak River Ins.",
    "Aftra Health Fund",
    "ULTRA BENEFITS",
    "Mosaic and Terrazzo Welfare Fund",
    "American Progressive Ins.",
    "Assurant Health Access/ASA",
    "PLUMBERS LOCAL UNION NO 1 WELF FUND",
    "Care Improvement Plus",
    "Medsolutions",
    "Aetna/HTH Worldwide",
    "Diversified Administration Corp.",
    "EMPLOYEE BENEFITS SOLUTIONS",
    "Administrative Concept Inc.",
    "Key Benefit Administrators",
    "GMMI",
    "State Wide Schools Cooperative Health Plan",
    "International Travel Assistance/ACI",
    "National Government Services, Inc.",
    "Administrative Services Only",
    "Dayton T.Brown Insurance",
    "Stirling Benefits",
    "Union Fidelity Life Ins. Co.",
    "United Health Care Student Recources",
    "Chartis Limited Medical Policy",
    "Colonial Penn LIfe Ins Co",
    "Comprehensive Benefits",
    "CHN",
    "US SECURITY ASSOCIATES",
    "Coordinated Benefit Plans",
    "EDO",
    "Quality Health Plans of NY",
    "Medfocus",
    "State Farm Health Division (Supplementary)",
    "PCIP",
    "WageWorks",
    "CDPHP",
    "Physicians United Plan Inc",
    "USAA LIFE INSURANCE COMPANY",
    "The Pyramid Life Insurance Company",
    "American Republic Insurance Company",
    "Aetna Meritain Health",
    "Compass Rose Heath Plan",
    "America's Choice Healthplan",
    "IMG",
    "EBPA",
    "Infinity Administrators",
    "Insurance Administration of America",
    "Health Republic of NY",
    "Health Smart",
    "Affinity Essentials Obamacare",
    "Omni Administrators",
    "MSA Processing Center/ Health Now",
    "Rural Carrier Benefit Plan",
    "HPHC",
    "New Era Life",
    "WEB-TPA/MULTIPLAN",
    "Alphacare of NY",
    "WEBTRA",
    "Life and Casualty Company",
    "Wage Parity/Multiplan",
    "NYNM IPA",
    "North Shore LIJ",
    "Christian Brothers",
    "Wellnet",
    "AMA INSURANCE AGENCY INC",
    "Health Scope Benefits",
    "PMC HealthCare",
    "TOTAL PLAN CONCEPTS",
    "Amida Care INC",
    "Centers Plan For Healthy Living/ Relay Health",
    "Davis Vision",
    "Aetna ACI",
    "BLOCK VISION",
    "Agewell NY C/O RELAY HEALTH",
    "March Vision",
    "Archcare Health Insurance",
    "Village Senior Services",
    "VSP",
    "LIBERTY HEALTH ADVANTAGE",
    "NAPHCARE",
    "Elderserve Health Inc.",
    "Oxford Insurance",
    "Senior Whole Health",
    "AETNA BETTER HEALTH",
    "Standard Life insurance",
    "EyeQuest",
    "Geisinger Health Plan",
    "Oscar Health Insurance",
    "EYEMED VISION CARE",
    "Care Connect",
    "Northrop Grumman",
    "Independence Care System",
    "National Elevator Industry",
    "Integra C/O Relay Health",
    "Crystal Run Health Plan",
    "Celtic Insurance Company",
    "Mercer Consumer",
    "Commonwealth Health Insurance",
    "Elant Choice Insurance",
    "Sanus Health Corporation",
    "World Travel Assist",
    "Independent Health",
    "Consolidated Health Plans",
    "Allianz Global Assistance",
    "Tokio Marine HCC",
    "Molina Healthcare",
    "MSH/First Health",
    "AMERICAN ASSIST TRAVEL SERVICES",
    "Optima Health Plan",
    "Euro-Center USA, Inc.",
    "C & R Consulting",
    "Total Care",
    "Unicare",
    "Hereford",
    "Kaiser Permanente Insurance Company",
    "UPMC Health Plans",
    "United Healthcare Community Plan",
    "Golden Rule Insurance Co",
    "Medical Mutual",
    "AXA Assistance",
    "Seven Corners, Inc.",
    "WPS Health Insurance",
    "Pennsylvania Lumbermans Insurance",
    "Security Health Plan",
    "World Assist Corp",
    "American Healthcare Alliance",
    "CanAssistance",
    "Medcost Benefit Services",
    "Unified Life Insurance Company",
    "Planned Administrators, Inc.",
    "Connecticare",
    "World Medical Care",
    "Veterans Benefit Administration",
    "Viva Health",
    "Tufts",
    "Foreign Service Benefit Plan",
    "American Transit Insurance Company",
    "Healthgram",
    "Unity Health",
    "Balt Assistance",
    "Priority Health",
    "Moda Health Plan",
    "GEICO",
    "Self Pay",
    "Progressive",
    "1Cover",
    "Atlantic Insurance",
    "UHC Shared Services",
    "Assist Card of Florida",
    "ASR Health Benefits",
    "Global Insurance Management",
    "Global Excel Management",
    "International Logistics and Solutions",
    "Globe Life Insurance Company of New York",
    "Hereford Insurance Company",
    "Embassy of Australia Defence Branch",
    "MDabroad/US & UK Medical Abroad LLC",
    "Cambridge Administrators",
    "NHP Prime HMO",
    "Medi-Share",
    "DKV Seguros",
    "AvMed",
    "United Health International",
    "The Loomis Company",
    "UCHealth Plan Administrators",
    "Medical Associates Health Plans",
    "Guardian",
    "21st Century",
    "Commercial Travelers",
    "Findlay",
    "Allegiance",
    "Providence Health Plan",
    "Gila River",
    "Aetna",
    "IHC Health Solutions",
    "Allied National",
    "PacificSource",
    "All Savers",
    "Colorado Choice Health Plans",
    "Prudent Buyer Plan",
    "Claims at TuGo",
    "Local 306",
    "SHL Claims",
    "Mayo Clinic Health Solutions",
    "New Frontier Group",
    "Ameriben",
    "Chubb Insurance",
    "Cigna Health Spring",
    "Yale Health",
    "United Health Care EP",
    "Hertz Claims Management",
    "John Muir Physician Network",
    "Sanford Health Plan",
    "Travelers Melville Claims",
    "UHA",
    "Partners Health Plan",
    "Health Partners",
    "Relationl Insurance Administrators",
    "Leading Edge Administrators",
    "SecureOne",
    "Allstate Insurance",
    "Cal Optima",
    "Elco Administrative Services",
    "Banner Choice Plus",
    "UnitedHealthcare Global",
    "Doctors Now LLC.",
    "Interprime Management Inc.",
    "Travel Care Services",
    "Health Plans",
    "Universal Healthcare",
    "Travel Guard",
    "The Navajo Nation",
    "American Medical Network",
    "AIG Travel",
    "CarePlus",
    "Aetna - GEHA",
    "NetServices",
    "Quantico Assistance Services",
    "Superior Health Plan",
    "Aultcare Insurance",
    "American Public Life",
    "Western Health Advantage",
    "CareMed Claims",
    "JP Fraley Corp",
    "Asuris",
    "CountryWide Insurance",
    "QualChoice",
    "HAA Preferred Partners LLC",
    "SilverScript",
    "Caitlin Indemnity",
    "Express Doctors LTD",
    "Sutter Health Plus",
    "Coventry Health Care",
    "Bupa International Insurance",
    "Christy Student Health",
    "Mass Health",
    "Clover health",
    "Ambetter from Sunshine Health",
    "Kaiser Northern",
    "Kaiser Southern",
    "Kaiser Seattle",
    "Mega Assistance",
    "Global Liberty",
    "Preferred One",
    "Tricare West",
    "The Kempton Group",
    "Nova Health Administrators",
    "Universal Assistance",
    "RSI Reimbursement",
    "Willse and Associates",
    "Medica",
    "HFHP",
    "Aetna Ins.",
    "Fidelis Care",
    "Healthez",
    "Cigna Healthcare Ins",
    "CareSource",
    "Axis Services",
    "Local 670 VIP",
    "Coresource - Gulf Stream",
    "Healthcare Partners",
    "Broadspire",
    "PEHP",
    "AGVA Welfare Trust Fund",
    "Benefit Administrative Systems, LLC",
    "Secure Health",
    "Empire Physicians Medical Group",
    "American Indian Health",
    "CISI",
    "Pacific Blue Cross",
    "Forethought Life Insurance Company",
    "GW Life",
    "Trans America",
    "Cofinity",
    "First Choice",
    "Priority Partners, MCO",
    "Easy Choice Health Plan",
    "First Choice Health Administrators",
    "IU Health Plans",
    "Liberty Mutual",
    "FirstCare",
    "Liberty Healthshare",
    "Ambetter from Health Net",
    "Travelers Insurance",
    "Stationary Engineers Local 670",
    "Affinity Health Plan",
    "Affinity Health Plan Medicare Advantage",
    "TPA",
    "April USA",
    "Network Health MA Plans",
    "Omint",
    "Populytics",
    "Community Care",
    "Prestige Health Choice",
    "Crum & Foster Insurance",
    "NYSDOH Uninsured Care",
    "Ucare",
    "Culinary Health Fund",
    "All State NJ",
    "Health Alliance Plan",
    "Health New England",
    "NYCM Insurance",
    "Central California Alliance",
    "Integon Casualty Insurance",
    "Zurich Insurance",
    "The Alliance",
    "Inland Empire Health Plan",
    "Meridian Health",
    "BMC Health Plan",
    "Lehigh Valley Health Network",
    "Gallagher Bassett",
    "AmTrust North America",
    "Amida Care",
    "MedStar",
    "Administrative Concept",
    "Benefit Plan Administrators",
    "Corvel Corp",
    "Fallon Health",
    "IUOE Local 137",
    "MinutemanHealth",
    "Cypress Benefit Administrators",
    "Peach State Health Plan",
    "Healthchoice",
    "SelectCare Worldwide",
    "Metlife",
    "Western Growers Assurance Trust",
    "Aliera Healthcare Unity",
    "Alberta Blue Cross",
    "HealthNow",
    "GBG- Global Benefits",
    "Homefirst",
    "Guarantee Insurance Company",
    "Europ Assistance",
    "KPIC",
    "MMM Healthcare",
    "Health Plan of San Joaquin",
    "MCS Plaza",
    "ACE American",
    "Constellation Health",
    "NYC Transit Authority",
    "Husky",
    "Sedgwick",
    "The Hartford",
    "WellMed",
    "TMCA",
    "Keystone First",
    "Health Partners Plans",
    "Desjardins Insurance",
    "Village Care Max",
    "CTC Benefits",
    "Persistant Systems",
    "VIP Universal Medical",
    "Spencer Stuart",
    "ASPE Health Benefits",
    "AmeriHealth PA",
    "LM General Ins.",
    "State Farm Auto Claims",
    "Mitsui Sumitomo Insurance Group",
    "Patriot Risk Services c/o MCMC",
    "Allianz Worldwide Care",
    "Assured Life Association",
    "Town and Gardens Ltd",
    "Entrust Claims Team",
    "GPA",
    "Palmetto GBA",
    "Fringe Benefit Group",
    "Maid Stone Insurance",
    "Cigna Global Health Benefits",
    "Hanover",
    "Continental Benefits",
    "New Jersey Manufacturerers Ins.",
    "Erie Insurance Group",
    "MCS Insurance Company",
    "Health Net MC",
    "CoreSource KC",
    "MagnaComp",
    "Integon National Insurance Company",
    "Workers Comp Board",
    "Innovative Health Plan",
    "Safeco Insurance",
    "MedStar Family Choice",
    "Key Solutions",
    "Carefirst/Network Leasing",
    "Verizon Comm",
    "University of Utah Health Plans",
    "Allianz Global of Toronto",
    "ACE/ESIS",
    "NY Black Car Fund",
    "Employee Benefit Management",
    "HealthPlan Services",
    "Piedmont Community HealthCare",
    "Travelex",
    "AmeriHealth Administrators",
    "Sentinel Security",
    "Farmers Insurance",
    "Helmsman Management Services",
    "Whole Food Market",
    "EBSO",
    "Partners in care",
    "Brown & Toland",
    "NYHTC",
    "Network Health Plan",
    "Colonial Medical Insurance",
    "Wellcare Fl",
    "Active Care Management/ Manulife",
    "Division 1181",
    "Axis Insurance Company",
    "RMPG",
    "CarePlus Travel",
    "Consolidated Edison Co. of NY, Inc.",
    "EMPIRE BLUE CROSS / BLUE SHIELD",
    "Local 365",
    "Exceedent",
    "Healthcare Management Administrators",
    "Bright Health",
    "WC",
    "FL Medicare",
    "US Family Health Plan at Martin's Point",
    "Aetna Better Health of Florida",
    "Maya Insurance",
    "Claims Service Bureau",
    "Brain and Spine Institute",
    "Abrams Fensterman",
    "Aetna Bermuda",
    "Mutual Health Services",
    "Health Plan of Nevada",
    "Blue Cross Travel",
    "Department of Veterans Affairs",
    "NYSIF",
    "Ambetter From Superior",
    "Delta Health Systems",
    "Preferred Care Partners",
    "LHS Funding Corp",
    "Progressive Insurance",
    "United Healthcare Community Plan",
    "State Mutual Insurance Company",
    "Sag Producers Health Plan",
    "Metroplus",
    "National Liability and Fire Insurance",
    "Humana MD",
    "Kaiser self funded",
    "Unified IPA",
    "ABC",
    "Destination Care",
    "National General Insurance",
    "Transparent Health",
    "Equian",
    "MVAIC Motor Vehicle & Demification",
    "Century Healthcare, LLC c/o WebTPA",
    "AmeriHealth Caritas NorthEast",
    "Healthy Montana Kids Plus",
    "VNS Hospice",
    "Chubb USA Claims",
    "Philadelphia American Life",
    "ABM",
    "Crossover Health",
    "Navigators Insurance Company",
    "American Collectors Insurance",
    "Cayuga Centers",
    "Hamaspik Choice Inc",
    "Ausa Mastercare Group Ins.",
    "National General Accident & Health",
    "Miami City Ballet",
    "HMA, LLC",
    "Escreen",
    "B'nai B'rith Insurance Program",
    "Covenant Administrators, Inc",
    "Steadfast",
    "TB Purdy Restaurant LLC",
    "eba&m",
    "Excellus Health Plan",
    "Manitoba Blue Cross",
    "Scan Claims Department",
    "Wright Risk Management",
    "Tricare For Life",
    "HealthSmart",
    "UnitedLex Corp",
    "HealthSun",
    "OUSDHP",
    "CareGuard",
    "Reliable Life Insurance Company",
    "Lifestyle Health Plans",
    "Scott & White Health Plan",
    "Staywell Health Plan",
    "TM Claims Service, Inc",
    "Med3000",
    "Great Western Insurnace",
    "Benefit Support, Inc",
    "United Agricultural Benefit Trust",
    "Zelis Healthcare",
    "Group Health Cooperative",
    "HOP Administration Unit",
    "Cost Containment Facilities",
    "Assistel inc",
    "Summit America Insurance",
    "Connecticare MC",
    "Shoprite Garnerville",
    "Medical Staffing Network",
    "Northeast Carpenters",
    "Group Benefit Services, Claims Depatment",
    "Concierge Administrative Services",
    "CGS Health",
    "IMA, Inc",
    "American Country Insurance Company",
    "SunMed International",
    "Health Alliance",
    "Mami Health",
    "To Go",
    "Daycare",
    "Park Insurance",
    "Fire Department",
    "Medica 2",
    "UCS-nawua-46898(Aegis)",
    "Summit Holding",
    "Menin Hospitality",
    "Esis Southeast",
    "Zenith Insurance",
    "All Sea Medical",
    "US Family Health Plan WA",
    "Corvel",
    "Travelers WC",
    "Administrative Employer Driven Solutions",
    "ConnectYourCare LLC",
    "Grand Beach Hotel Miami",
    "AON Risk Services Inc.",
    "United Health Care",
    "Aetna Student Health",
    "Sedgwick FL",
    "Sentry Insurance",
    "ROJW-Health Care Support",
    "HPSM Claims",
    "American Independent Insurance",
    "CAS",
    "New Mexico Health Connections",
    "Administrative Services Only, INC",
    "McLaren Health Advantage",
    "Foremost Insurance",
    "Quik Trip",
    "Grand Beach Hotel Surfside",
    "Atlas/Global Liberty",
    "Health Net Plan",
    "CNA",
    "Equitable",
    "Benefit Administrative System",
    "Orange County Superior Concrete",
    "Vivo Health",
    "Liberty Bankers",
    "Healthlink, Inc",
    "Employee Benefit Solutions",
    "EAN Holding LLC",
    "Partners Health Plan",
    "Med-Pay",
    "SPX Cooling Technologies, Inc",
    "CSEA Local 1000",
    "HealthSmart Benefit Solutions",
    "Celtic Sheet Metal",
    "National Marrol Donor Program",
    "Travel Claim Services",
    "One Beacon",
    "Alliance National Insurance",
    "State- Wide Schools Cooperative H",
    "Greenwich Insurance Company",
    "Helmsman management Service",
    "Ameriprise",
    "PMA",
    "USAA no fault",
    "Louisiana Healthcare Connections",
    "Associated Industries Insurance",
    "Medico Life Insurance Company",
    "Health west",
    "Paramount",
    "Dunn and Associates",
    "Surgeon Invoice",
    "Samaritan Health Plans",
    "Tappan Fire District",
    "Community Health Options",
    "Under 30",
    "The Main Street America Group",
    "Quartz",
    "LifeWise Assurance company",
    "Special Insurance Services, Inc",
    "Claims Administration",
    "Hudson Insurance Group",
    "NYC Comptroller",
    "BRMS",
    "TSC Direct",
    "REDBRIDGE TRAVEL",
    "MVP Health Ins.",
    "Liberty Mutual",
    "Leader Network Group",
    "Manhattan Life Insurance Company",
    "NASI Wellcare Fund",
    "Chambers of Commerce",
    "Wellfleet",
    "FCS Administrators, Inc",
    "Freedom Life Insurance",
    "Wallach & Company, Inc",
    "Great West Life Assurance C/o UHC Global Assistance",
    "Global Claims Administration",
    "SIHO",
    "SummaCare",
    "South Beach Group",
    "Combined Ins Company of America",
    "Insurance Management Services",
    "Centerlight Health System",
    "Wohl Diversified",
    "Ace American Insurance",
    "Next Level Administrators",
    "6 Degrees Health",
    "Solidarity Healthshare",
    "WEGMANS FOOD MARKETS",
    "ATRIA MANAGEMENT COMP",
    "Samba",
    "Western & Southern Financial Group",
    "PAL ENVIRONMENTAL SERVICES",
    "CITY OF NY HRA- HCSP",
    "NGM Insurance co",
    "SEVENTEEN BRIDGES LLC/17 BRIDGES RS",
    "PICROW STREAMING INC",
    "FIRST STEPS",
    "ResourceOne",
    "Lori's Gift Inc",
    "Allied Pacific",
    "Rising Medical Services",
    "Active Staffing",
    "Seventeen Bridges LLC",
    "Charter Communications",
    "UPS",
    "Imperial Fire & Casualty Insurance company",
    "Bajaj Allianz General Insurance",
    "No Fault Services Corp",
    "Benefit & Risk Management",
    "AIG Personal Accident",
    "Lonnie B. Richardson, ESQ.",
    "Adventist Health Employee Health Plan",
    "WORKCARE / WILLDAN GROUP",
    "Plymouth Rock Assurance",
    "Assigned Risk Solution",
    "allways health partners- aetna healthscope",
    "UnitedRefuah healthshare",
    "AUXIANT MIDLANDS CHOICE",
    "Gateway Health",
    "Ardent Insurance",
    "PInnacle claims Management INC",
    "Silverstone",
    "Health Scope Benefits NHP",
    "AB Staffing Solutions",
    "Good2go",
    "HealthSmart WTC",
    "NBC UNIVERSAL MEDIA LLC",
    "THE FACTORY EAST INC",
    "TITAN DRILLING",
    "FRUCTOSO VELEZ LANDSCAPING COMPANY",
    "YNFS PRODUCTIONS LLC",
    "CHR/ CORPORATE HEALTH RESOURCES",
    "COOLER WATERS PRODUCTION LLC",
    "Daniel H. Cook Associates, Inc.",
    "CCMSI, Frank Winston Crum Ins",
    "Community Health Choice, Inc",
    "Indecs",
    "Sharp Health Plan",
    "Royal Jordanian",
    "Triple Logistics",
    "Berkshire Hathaway Guard",
    "Clarity Testing Services",
    "PACKARD",
    "Dept of Sanitation",
    "NY PUBLIC INTEREST RESEARCH GROUP FUND",
    "CENTRAL DRUG SYSTEM INC",
    "TRIDENT",
    "Environmental Business Consltants",
    "Simply Healthcare Plans",
    "Guild Times Benefits Fund",
    "Assured Benefits Administrators",
    "NYC Law Department",
    "Esis",
    "CAVANAUGH WALL SOLUTION INC",
    "Auto Injury Solutions",
    "International Concrete",
    "Sompo",
    "Grand Beach Hotel Bay Harbor",
    "Windhaven Insurance Company",
    "Wa Globality",
    "Regional Care Inc",
    "Magna Comp",
    "MRCC Employee Benefits Fund",
    "Esurance",
    "Health EOS by multiplan",
    "CBS/Zelis",
    "Core Administrative Services, Inc",
    "Presbyterian Health Plan",
    "BritCay Insurance",
    "National General Insurance Company",
    "Philadelphia Insurance Companies",
    "NYCHA C/O RMPG",
    "Continental General Insurance",
    "Indiana University Health Plans",
    "SmartStart Employment Screening",
    "MEDPRO HEALTHCARE STAFFING",
    "MTA BUS C/O CORVEL",
    "1st Integral Support Traveller LLC",
    "HealthScope Benefits",
    "Network Adjusters Inc",
    "IUOE Local 25",
    "U.A. Local 393 Health & Welfare Plan",
    "CONSTITUTION STATE SERVICE",
    "The Global Benefit Group",
    "CCMSI",
    "Vacnn Optum",
    "Veteran with Triwest",
    "MedCare International",
    "Tall Tree Admin",
    "WMD COAST TO COAST TRANSPORT LLC",
    "PARTNERS IN CARE/ VNSNY",
    "NYC DEPARTMENT OF EDUCATION",
    "ALLIANCE HEALTH & SAFETY SERVICES",
    "DEPT OF LABOR",
    "On Call International",
    "RAZALINE BUILDERS INC",
    "MARRIOTT CLAIMS SERVICES",
    "Veterans Choice Program-VACAA",
    "Mapfre Life Insurance",
    "Medica 3",
    "Manhattan Theater Club",
    "JGM DECORATING INC",
    "CUBE CONSTRUCTION SERVICES",
    "Arthur J. Gallager & Co.",
    "Cherokee Insurance",
    "Priority Health",
    "United Automobile Insurance Company",
    "Health First Health Plans",
    "Doctors Healthcare Plans Inc",
    "Lewermark Student Health Insurance",
    "World Travel Protection",
    "Co-ordinted benefit plans",
    "Always Health Partners",
    "Utica Mutual ins Nf",
    "Rli insurance",
    "Worldwide Inc",
    "Triple-S Advantage Inc",
    "WPS-VAPC3",
    "Empire Blue Cross",
    "Security National Insurance",
    "SBMA",
    "Formfox",
    "Cramer Security and Investigation",
    "GREYSTONE HOTEL",
    "Insurance Benefit Claims",
    "Denis Davydov",
    "Christus Health Plan",
    "Admistrative solutions",
    "Soliant Health",
    "Kaiser Permanente Insurance Company",
    "Dongho Insurance",
    "National Casualty Company",
    "Usis",
    "University of Maryland Health Partners",
    "Ghost Productions, Inc.",
    "Aig Claim Services",
    "Health Choice",
    "Motivhealth insurance Comp",
    "Emi Health",
    "Optimum Healthcare",
    "Sedgwick NF",
    "Amc Drug Testing",
    "uber",
    "UnitedHealth Group / Uhg",
    "Clutter Inc",
    "State National Ins.",
    "Shared Health Alliance",
    "first Advantage",
    "CWI Benefits, Inc",
    "Amazon",
    "Rexpect productions LLC",
    "Bronx Da/ National claims evaluation",
    "Possible Prod. Inc",
    "Hydrochem Psc Dba",
    "Tc Transcontinental Ultraflex",
    "Netflix Productions llc",
    "CVS/Certiphi/ vertical screen",
    "Fresenius Medical care",
    "Navinix LLC",
    "Roadmaster/ Examvip",
    "Travel Nurse  Across Amer.",
    "Foley Services",
    "Camdrew Productions llc",
    "Menin Bodega  Taqueria And Teequila",
    "NYCSSS",
    "Canndy Construction",
    "Big Apple Group",
    "Bogopa Service corp",
    "Department of education",
    "Memorial Sloan Kettering",
    "Sultan Trans",
    "Aureus Medical/ C&A",
    "Ste4 136A Safety",
    "Covid 19 HRSA Uninsured",
    "Covid 19 HRSA Uninsured",
    "La Gorce",
    "Covid 19 HRSA Uninsured",
    "Oscar Health Ins",
    "Group Health Inc.",
    "United Healthcare Community Plan",
    "Blue Cross",
    "Aetna",
    "Emblem Health (HIP)",
    "Medicare",
    "1199 SEIU NATION BENEFIT FUND",
    "MVP Health Ins.",
    "Amerihealth NJ 20",
    "Aetna Meritain Health 20",
    "Blue Cross of NJ",
    "UHSM",
    "Blue Cross of NJ",
    "Trustmark Health Benefits 20",
    "Clover Health Investments",
    "LMC health plans",
    "Bright Health",
    "Sun Health and Dental",
    "Texas Childrens Health Plan",
    "IPMG Employee Benefit Services",
    "Sisco",
    "Trustmark Small Business Benefit",
    "Kaiser of Georgia",
    "Community Care Plans",
    "Medplan Health Solutions",
    "Community Care Plan",
    "Health Net Commercial",
    "City of Coral Gabels",
    "ADAM & ADAM transportation",
    "Quest Diagnostics",
    "South Fl Pizza",
    "Sunshine Health",
    "South Florida Community",
    "Devoted health plans",
    "Town of surfice",
    "Community First Health Plans",
    "North by village",
    "Novitas Solutions Att Part B",
    "Emerald health Services/ fssolutions",
    "LEON MEDICAL CENTERS",
    "Miami EPS",
    "Lucent Health",
    "Menin - Shelborne south beach",
    "Norton Medical Industries",
    "Mondrian south beach",
    "Menin Xcup & Bat",
    "Menin -  Pizza bar",
    "Miami beach fire dept.",
    "Police dept Bay Harbor",
    "Lakewood Organics",
    "Edi Trin1",
    "Town of bay harbor islands",
    "Tru Blue TPA",
    "Miami Spring Police Department",
    "Evolution Healthcare",
    "City Of Miami Beach",
    "Miami Beach Police Dept.",
    "Castle Management LLC Central",
    "Locatel-EPS",
    "Atlantic Pacific",
    "Bimini Shipping",
    "Walgreens",
    "MAGELLAN AUDIT-PACKAGE",
    "allan &Pierce EPS",
    "Perry Ellis EPS",
    "Miami beach Fire Dept.",
    "Maribel EPS",
    "Menin - Corporate",
    "Manulife",
    "Medimpact",
    "Gold Coast Health Plan",
    "Cornerstone Preferred Resources",
    "Bind Benefits, Inc.",
    "Atlantic Recovery Center",
    "Sharp Entertainment",
    "UnitedAg",
    "cg atlantic",
    "Bay Harbor",
    "Morrison Meat",
    "New York Central Mutual Fire Ins. Co",
    "Boon Chapman",
    "Film45",
    "FSSOLUTIONS",
    "Black car fund",
    "CHA Health plans",
    "Medica 78857",
    "BETSY HOSPITALITY MANAGEMENT",
    "T1 PAYMENTS",
    "PAMM MUSEUM",
    "ANAIS NAILS AND SPA",
    "MIAMI NEW DRAMA",
    "covid4",
    "Amerihealth Caritas Delaware",
    "Amerihealth Caritas Louisiana",
    "Netcare",
    "Kaiser Foundations Health Plans....",
    "MARITIME CONSORTUIM",
    "EBMS",
    "create",
    "Miami Cordage",
    "AFB INTERNATIONAL",
    "Healthcase",
    "Nyhealth International",
    "Blackhawk Claims Services, Inc.",
    "1Source",
    "1Course",
    "Vetinary Emergency Group",
    "Covid5",
    "self/eps",
    "tristate insurance company",
    "prevea360 health plan",
    "Coordinated Benefit",
    "Global Claims Administation",
    "JAM SHELL BUILDERS",
    "DORAL YARD",
    "UHC- self funded",
];