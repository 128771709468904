const options = [
    { 
        value: '', 
        text: 'Select Option' 
    },
    { 
        value: 'Hispanic or Latino', 
        text: 'Hispanic or Latino' 
    },
    { 
        value: 'Not Hispanic or Latino', 
        text: 'Not Hispanic or Latino' 
    },
    { 
        value: 'Unknown', 
        text: 'Unknown' 
    },
];

export default options;