const options = [
    {
        value: '',
        text: 'Select Race'
    },
    {
        value: 'Asian',
        text: 'Asian'
    },
    {
        value: 'Black',
        text: 'Black'
    },
    {
        value: 'Native American/Alaska Native',
        text: 'Native American/Alaska Native'
    },
    {
        value: 'Not Indicated',
        text: 'Not Indicated'
    },
    {
        value: 'Other',
        text: 'Other'
    },
    {
        value: 'Pacific Islander',
        text: 'Pacific Islander'
    },
    {
        value: 'Two Or More Races',
        text: 'Two Or More Races'
    },
    {
        value: 'Unknown',
        text: 'Unknown'
    },
    {
        value: 'White',
        text: 'White'
    },
];

export default options;