const options = [
    { 
        value: '', 
        text: 'Select Gender' 
    },
    { 
        value: 'F', 
        text: 'Female' 
    },
    { 
        value: 'M', 
        text: 'Male' 
    },
    { 
        value: 'O', 
        text: 'Other' 
    },
];

export default options;