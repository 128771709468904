const options = [
    {
        value: "",
        text: "Select Option"
    },
    {
        value: "LT Left Thigh",
        text: "LT Left Thigh"
    },
    {
        value: "LA Left Arm",
        text: "LA Left Arm"
    },
    {
        value: "LD Left Deltoid",
        text: "LD Left Deltoid"
    },
    {
        value: "LG Left Gluteous Medius",
        text: "LG Left Gluteous Medius"
    },
    {
        value: "LVL Left Vastus Lateralis",
        text: "LVL Left Vastus Lateralis"
    },
    {
        value: "LLFA Left Lower forearm",
        text: "LLFA Left Lower forearm"
    },
    {
        value: "RA Right Arm",
        text: "RA Right Arm"
    },
    {
        value: "RT Right Thigh",
        text: "RT Right Thigh"
    },
    {
        value: "RVL Right Vastus Laterlis",
        text: "RVL Right Vastus Laterlis"
    },
    {
        value: "RG Right Gluteous Medius",
        text: "RG Right Gluteous Medius"
    },
    {
        value: "RD Right Deltoid",
        text: "RD Right Deltoid"
    },
    {
        value: "RLFA Right Lower Forearm",
        text: "RLFA Right Lower Forearm"
    },
    {
        value: "OTH Other / Miscellaneous",
        text: "OTH Other / Miscellaneous"
    },
];

export default options;

